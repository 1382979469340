import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { device } from "../../../utils/mediaQueries"

const Container = styled.div`
  align-content: flex-start;
  padding: 0px 30px;
  text-align: center;
  margin: 0px auto;
  @media ${device.tablet} {
    padding: 10px;
  }
`

const Background = styled(BackgroundImage)`
  background-color: blue;
`

const HeaderWithPhoto = props => {
  return (
    <Background
      Tag="section"
      fluid={props.image}
      style={{ backgroundColor: "" }}
      alt={props.alt}
    >
      <Container>{props.children}</Container>
    </Background>
  )
}
export default HeaderWithPhoto
