import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Button = styled.div`
  font-size: 25px;
  background-color: white;
  border: 2px solid #0481b1;
  color: #0481b1;
  margin: 10px 0px;
  padding: 20px 30px;
  max-width: 300px;
  min-width: 100px;
  text-align: center;
  font-weight: 800;
  text-transform: capitalize;
  :hover {
    background-color: #0481b1;
    color: white;
  }
`

const CustomLink = styled(Link)`
  text-decoration: none;
`

const PageButton = props => {
  return (
    <CustomLink to={props.url}>
      <Button>{props.text}</Button>
    </CustomLink>
  )
}

export default PageButton
